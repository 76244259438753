<template>
    <div>
        <a-button type="primary" v-if="isAdmin" @click="showDrawer" :style="{ marginBottom: '10px'}">
            添加
        </a-button>

        <el-table
            ref="table"
            @on-mounted="showCompanyList=true"
            :path="path"
            :columns="columns"
            :actions="actions"
            :params="params"
            @on-edit="edit"
            :search="search"
        ></el-table>

        <a-modal
            title="公司信息"
            :width="720"
            v-model:visible="visible"
            :body-style="{ paddingBottom: '80px' }"
            @close="onClose"
        >
            <a-form :model="formData" :rules="rules" ref="formRef" layout="vertical">
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-item label="公司名称" name="name">
                            <a-input v-model:value="formData.name" placeholder="请输入公司名称"/>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="税号" name="code">
                            <a-input v-model:value="formData.code" placeholder="请输入公司税号"/>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-item label="联系人" name="linkMan">
                            <a-input v-model:value="formData.linkMan" placeholder="请输入联系人"/>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="联系方式" name="phone">
                            <a-input v-model:value="formData.phone" placeholder="请输入联系方式"/>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-item label="规模" name="scope">
                            <a-input v-model:value="formData.scope" placeholder="请输入规模"/>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="地址" name="address">
                            <a-input v-model:value="formData.address" placeholder="请输入公司地址"/>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-item label="服务状态" name="state">
                            <a-radio-group name="radioGroup" v-model:value="formData.state">
                                <a-radio value="1">服务中</a-radio>
                                <a-radio value="0">禁用</a-radio>
                            </a-radio-group>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="服务时长" name="time">
                            <a-range-picker @change="setTime" v-model:value="formData.time" :valueFormat="dateFormat"/>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-item label="短信余量" name="margin">
                            <a-input-number :min="0" style="width: 100%" v-model:value="formData.margin"
                                            placeholder="请输入短信余量"/>
                        </a-form-item>
                    </a-col>
                </a-row>
<!--                <a-row :gutter="16">-->
<!--                    <a-col :span="12">-->
<!--                        <a-form-item label="负责人账号">-->
<!--                            <a-input v-model:value="formData.code" placeholder="请输入账号" readonly/>-->
<!--                        </a-form-item>-->
<!--                    </a-col>-->
<!--                    <a-col :span="12">-->
<!--                        <a-form-item label="密码" name="password">-->
<!--                            <a-input type="password" v-model:value="formData.password" placeholder="请输入密码"/>-->
<!--                        </a-form-item>-->
<!--                    </a-col>-->
<!--                </a-row>-->
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-item label="备注" name="remarks">
                            <a-textarea
                                v-model:value="formData.remarks"
                                :rows="4"
                                placeholder="请输入备注"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
            <div
                :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      }"
            >
                <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
                <a-button type="primary" @click="onSubmit">保存</a-button>
            </div>
        </a-modal>
    </div>


</template>

<script lang="ts">
import {defineComponent, reactive, ref, h, UnwrapRef, createVNode} from 'vue';
import Company from "@/request/Company";
import {message} from "ant-design-vue";
import SessionStorageKeys from "@/common/SessionStorageKeys";

const companyId = ref();
const isAdmin = ref<boolean>(false)
export default defineComponent({
    name: 'Company',
    beforeCreate() {
        isAdmin.value = sessionStorage.getItem(SessionStorageKeys.type)  === 'admin'
        companyId.value = isAdmin.value ? '' : sessionStorage.getItem(SessionStorageKeys.companyId);
    },
    setup() {
        const formRef = ref();

        const path = ref(Company.path);

        const table = ref();
        const dateFormat = ['YYYY-MM-DD'];

        const formData: UnwrapRef<any> = reactive({
            id: null,
            name: '',
            code: '',
            address: '',
            scope: '',
            linkMan: '',
            phone: '',
            state: '1',
            // account: '',
            // password: '',
            time: '',
            startTime: '',
            endTime: '',
            margin: '',
        });

        const params = ref({
            name: '',
            state: '',
            companyId: companyId
        })

        const rules = {
            name: [{required: true, message: '请输入公司名称'}],
            code: [{required: true, message: '请输入公司税号'}],
            linkMan: [{required: true, message: '请输入公司联系人'}],
            phone: [{required: true, message: '请输入联系方式'}],
            scope: [{required: false, message: '请输入公司规模'}],
            address: [{required: true, message: '请输入公司地址'}]
        };

        const visible = ref<boolean>(false);

        const showDrawer = () => {
            visible.value = true;
            formRef.value ? formRef.value.resetFields() : '';
            formData.id = null;
            formData.name = '';
            formData.code = '';
            formData.address = '';
            formData.scope = '';
            formData.linkMan = '';
            formData.phone = '';
            formData.state = '1';
            // formData.account = '';
            // formData.password = '';
            formData.time = '';
            formData.startTime = '';
            formData.endTime = '';
            formData.margin = '';
        };

        const onClose = () => {
            visible.value = false;
            formRef.value.resetFields();
        };

        const edit = (data: any) => {
            visible.value = true;
            // formData.value = data;
            formData.id = data.id
            formData.name = data.name
            formData.code = data.code
            formData.address = data.address
            formData.scope = data.scope
            formData.linkMan = data.linkMan
            formData.phone = data.phone
            formData.margin = data.margin
            formData.time = [data.startTime, data.endTime]
            formData.state = data.state + ''
            formData.remarks = data.remarks
        }

        const setTime = (data: any) => {
            formData.startTime = data[0]
            formData.endTime = data[1]
        }

        const onSubmit = () => {
            formRef.value
                .validate()
                .then(() => {
                    Company.save(formData).then((body: any) => {
                        if (body.code === 0) {
                            visible.value = false;
                            formRef.value.resetFields();
                            table.value.flush(); //刷新表格
                            message.info("保存成功！")
                        } else {
                            message.error(body.msg);
                        }
                    })
                })
        }

        const actions = ref<Actions[]>([
            {
                name: '编辑',
                event: 'edit'
            },
            {
                name: '删除',
                event: 'del'
            }
        ]);

        return {
            showCompanyList: ref<boolean>(false),
            path,
            formData,
            onSubmit,
            rules,
            visible,
            showDrawer,
            dateFormat,
            onClose,
            formRef,
            params,
            setTime,
            edit,
            actions,
            table,
            isAdmin: isAdmin,
            search: ref([
                {
                    tag: 'input',
                    label: '公司名',
                    key: 'name'
                },
                {
                    tag: 'select',
                    label: '状态',
                    key: 'state',
                    width: '100px',
                    options: [
                        {
                            key: '0',
                            value: '禁用'
                        },
                        {
                            key: '1',
                            value: '服务中'
                        }
                    ]
                }
            ]),
            columns: ref([
                {
                    dataIndex: 'name',
                    title: '公司名'
                },
                {
                    dataIndex: 'code',
                    title: '税号'
                },
                {
                    dataIndex: 'address',
                    title: '地址'
                },
                {
                    dataIndex: 'linkMan',
                    title: '负责人'
                },
                {
                    dataIndex: 'phone',
                    title: '联系电话'
                },
                {
                    key: 'id',
                    title: '服务期限',
                    customRender: ({record}: any) => {
                        return h(
                            'div',
                            {},
                            h('p',
                                {},
                                (record.startTime && record.endTime) ? (record.startTime + '至' + record.endTime) : ''
                            )
                        )
                    }
                },
                {
                    dataIndex: 'margin',
                    title: '短信余量(条)'
                },
                {
                    dataIndex: 'state',
                    title: '状态',
                    customRender: ({record}: any) => {
                        return h(
                            'div',
                            {},
                            h(
                                'p',
                                {},
                                record.state === 0 ? '禁用' : record.state === 1 ? '服务中' : ''
                            )
                        )
                    }
                },
                {
                    title: '操作',
                    align: 'center',
                    key: 'action',
                    slots: {customRender: 'action'},
                    fixed: 'right',
                    width: 150
                }
            ])
        }
    }
})
</script>


<style lang="less">
.action {
    padding: 0 5px 0 5px;
}
</style>