
import {defineComponent, reactive, ref, h, UnwrapRef, createVNode} from 'vue';
import Company from "@/request/Company";
import {message} from "ant-design-vue";
import SessionStorageKeys from "@/common/SessionStorageKeys";

const companyId = ref();
const isAdmin = ref<boolean>(false)
export default defineComponent({
    name: 'Company',
    beforeCreate() {
        isAdmin.value = sessionStorage.getItem(SessionStorageKeys.type)  === 'admin'
        companyId.value = isAdmin.value ? '' : sessionStorage.getItem(SessionStorageKeys.companyId);
    },
    setup() {
        const formRef = ref();

        const path = ref(Company.path);

        const table = ref();
        const dateFormat = ['YYYY-MM-DD'];

        const formData: UnwrapRef<any> = reactive({
            id: null,
            name: '',
            code: '',
            address: '',
            scope: '',
            linkMan: '',
            phone: '',
            state: '1',
            // account: '',
            // password: '',
            time: '',
            startTime: '',
            endTime: '',
            margin: '',
        });

        const params = ref({
            name: '',
            state: '',
            companyId: companyId
        })

        const rules = {
            name: [{required: true, message: '请输入公司名称'}],
            code: [{required: true, message: '请输入公司税号'}],
            linkMan: [{required: true, message: '请输入公司联系人'}],
            phone: [{required: true, message: '请输入联系方式'}],
            scope: [{required: false, message: '请输入公司规模'}],
            address: [{required: true, message: '请输入公司地址'}]
        };

        const visible = ref<boolean>(false);

        const showDrawer = () => {
            visible.value = true;
            formRef.value ? formRef.value.resetFields() : '';
            formData.id = null;
            formData.name = '';
            formData.code = '';
            formData.address = '';
            formData.scope = '';
            formData.linkMan = '';
            formData.phone = '';
            formData.state = '1';
            // formData.account = '';
            // formData.password = '';
            formData.time = '';
            formData.startTime = '';
            formData.endTime = '';
            formData.margin = '';
        };

        const onClose = () => {
            visible.value = false;
            formRef.value.resetFields();
        };

        const edit = (data: any) => {
            visible.value = true;
            // formData.value = data;
            formData.id = data.id
            formData.name = data.name
            formData.code = data.code
            formData.address = data.address
            formData.scope = data.scope
            formData.linkMan = data.linkMan
            formData.phone = data.phone
            formData.margin = data.margin
            formData.time = [data.startTime, data.endTime]
            formData.state = data.state + ''
            formData.remarks = data.remarks
        }

        const setTime = (data: any) => {
            formData.startTime = data[0]
            formData.endTime = data[1]
        }

        const onSubmit = () => {
            formRef.value
                .validate()
                .then(() => {
                    Company.save(formData).then((body: any) => {
                        if (body.code === 0) {
                            visible.value = false;
                            formRef.value.resetFields();
                            table.value.flush(); //刷新表格
                            message.info("保存成功！")
                        } else {
                            message.error(body.msg);
                        }
                    })
                })
        }

        const actions = ref<Actions[]>([
            {
                name: '编辑',
                event: 'edit'
            },
            {
                name: '删除',
                event: 'del'
            }
        ]);

        return {
            showCompanyList: ref<boolean>(false),
            path,
            formData,
            onSubmit,
            rules,
            visible,
            showDrawer,
            dateFormat,
            onClose,
            formRef,
            params,
            setTime,
            edit,
            actions,
            table,
            isAdmin: isAdmin,
            search: ref([
                {
                    tag: 'input',
                    label: '公司名',
                    key: 'name'
                },
                {
                    tag: 'select',
                    label: '状态',
                    key: 'state',
                    width: '100px',
                    options: [
                        {
                            key: '0',
                            value: '禁用'
                        },
                        {
                            key: '1',
                            value: '服务中'
                        }
                    ]
                }
            ]),
            columns: ref([
                {
                    dataIndex: 'name',
                    title: '公司名'
                },
                {
                    dataIndex: 'code',
                    title: '税号'
                },
                {
                    dataIndex: 'address',
                    title: '地址'
                },
                {
                    dataIndex: 'linkMan',
                    title: '负责人'
                },
                {
                    dataIndex: 'phone',
                    title: '联系电话'
                },
                {
                    key: 'id',
                    title: '服务期限',
                    customRender: ({record}: any) => {
                        return h(
                            'div',
                            {},
                            h('p',
                                {},
                                (record.startTime && record.endTime) ? (record.startTime + '至' + record.endTime) : ''
                            )
                        )
                    }
                },
                {
                    dataIndex: 'margin',
                    title: '短信余量(条)'
                },
                {
                    dataIndex: 'state',
                    title: '状态',
                    customRender: ({record}: any) => {
                        return h(
                            'div',
                            {},
                            h(
                                'p',
                                {},
                                record.state === 0 ? '禁用' : record.state === 1 ? '服务中' : ''
                            )
                        )
                    }
                },
                {
                    title: '操作',
                    align: 'center',
                    key: 'action',
                    slots: {customRender: 'action'},
                    fixed: 'right',
                    width: 150
                }
            ])
        }
    }
})
